h1{
	margin-left:4%;
}

.weather_type_checklist{display:flex;
	flex-direction:row;
	margin: auto;
	width: 40%;
	border: 3px solid green;
	padding: 10px;
}

.weather_type_column{
	padding-left: 120px;
}

.terra-div{
	width: 1300px;
	/* width: 150%; */
}

.blue-patch-div{
	position: absolute;
	/* left: 99%; */
	left: calc(100% - 3px);
	top: 0px;
	/* width: 930px; */
	width: calc(1303px - 100%);
	height: 60px;
	background-color: #2E3B55;
}

.slider{display:flex; 

	/* flex-direction:row; */
	/* padding-left: 250px; */
	position: absolute;
	/* left: 325px;
	top: 390px; */
	stroke: white;
	/* width: 120px; */
}

.playbuttondiv{
	visibility: hidden;
}

.play-button{display:flex; 

	/* flex-direction:row; */
	/* padding-left: 250px; */
	position: relative;
	left: 300px;
	top: 95px;
	
	/* background-color: #4CAF50; */
	/* stroke: white; */
	/* width: 120px; */
}


.dattim{display:flex; 

	/* flex-direction:row; */
	/* padding-left: 250px; */
	position: absolute;
	/* left: 490px;
	top: 375px; */
	stroke: white;
	/* width: 120px; */
}


.map{padding-left: 30px;
}

.axisWhite line{
	stroke: white;
  }
  
.axisWhite path{
	stroke: white;
  }
  
.axisWhite text{
	fill: white;
  }

.value-simple{
	color: #fff;
}

.value-dattim{
	color: #fff;
}

.title{
	margin: auto;
	width: 19%;
	padding: 10px;
	padding-left: 75px;
}


#tips-list-div {
	float:left;
	width: 400px;
}

#terra-notifications-list-div {
	float:left;
	width: 400px;
	text-align: center;
}

#tips-notifications-div {
	width: 880px;
	height: 260px;
	background-color: #b8b7b6;
	margin-left:40px;
}

 
.wide-box-div {
	height: 250px;
	width: 800px;
	box-sizing: border-box;
	margin-left:40px;
	display: inline-block;
	}

#weather-type-selection-div {
	background-color: #b8b7b6;
	height: 160px;
	height: 260px;
	margin:auto;
	width: 180px;
	box-sizing: border-box;
	display: inline-block;
	
}

#tips-notifications-general-div {
	height: 160px;
}

#main-box {
	width:1400px;
	height:240px;
	padding-top:5px;
	padding-left:15px;
	padding-right:15px;
	display:flex;
	/* border: 1px solid black; */
}

#general-message-div {
	float:left;
	width: 400px;
	padding-left: 25px;
}

ul.myUL {
	display: inline-block;
	text-align: left;
	margin: 0;
}

#main-box-2 {
	width:1400px;
	height:60px;
	padding-top:5px;
	padding-bottom:35px;
	padding-left:15px;
	padding-right:15px;
	display:flex;
	/* border: 1px solid black; */
}

#your-weather-type-div {
	padding-top: 8px;
	height: 60px;
	margin:left;
	width: 630px;
	box-sizing: border-box;
	display: inline-block;
}

#signup-div {
	float:left;
	width: 300px;
	text-align: center;
	padding:27px;
	padding-left:50px;
}

#GFG {
	text-decoration: none;
}









  

